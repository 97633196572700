.mpc-4-background-daytime-mode-202302-icon2 {
  position: absolute;
  top: 5576px;
  left: 0;
  width: 1920px;
  height: 2586px;
  object-fit: cover;
}
.mpc-4-use-cases-of {
  position: absolute;
  top: 262px;
  left: 190px;
  font-size: var(--mpc-4-font3-size);
  line-height: 56px;
  display: flex;
  text-align: left;
  align-items: center;
  width: 764px;
  height: 52px;
}
.mpc-4-assets-like-iota {
  position: relative;
  font-size: var(--mpc-4-font-size);
  color: var(--mpc-4-light-t1-day);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 416px;
  height: 220px;
  flex-shrink: 0;
}
.mpc-4-enter-child {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--mpc-4-br-base);
  background-color: var(--mpc-4-dark-t1);
  border: 4px solid var(--mpc-4-dark-tanglepay);
  box-sizing: border-box;
}
.mpc-4-enter1 {
  position: absolute;
  height: 80%;
  width: 93.75%;
  top: 10%;
  left: 3.57%;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mpc-4-enter {
  position: relative;
  width: 244px;
  height: 80px;
  flex-shrink: 0;
  font-size: var(--mpc-4-font2-size);
  color: var(--mpc-4-dark-tanglepay);
}
.mpc-4-bridge-parent {
  position: absolute;
  top: 394px;
  left: 190px;
  border-radius: var(--mpc-4-br-9xl);
  background-color: var(--mpc-4-dark-t1);
  border: 4px solid var(--mpc-4-dark-b1);
  box-sizing: border-box;
  width: 492px;
  display: flex;
  flex-direction: column;
  padding: var(--mpc-4-padding-21xl);
  align-items: center;
  justify-content: center;
  gap: var(--mpc-4-gap-17xl);
}
.mpc-4-oracle {
  position: relative;
  line-height: 48px;
  width: 387px;
  height: 40px;
  flex-shrink: 0;
}
.mpc-4-oracle,
.mpc-4-oracle-can-be,
.mpc-4-oracle-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mpc-4-oracle-can-be {
  position: relative;
  font-size: var(--mpc-4-font-size);
  color: var(--mpc-4-light-t1-day);
  width: 388px;
  height: 220px;
  flex-shrink: 0;
}
.mpc-4-oracle-parent {
  position: absolute;
  top: 394px;
  left: 1270px;
  border-radius: var(--mpc-4-br-9xl);
  background-color: var(--mpc-4-dark-t1);
  border: 4px solid var(--mpc-4-dark-b1);
  box-sizing: border-box;
  width: 460px;
  flex-direction: column;
  padding: var(--mpc-4-padding-21xl);
  gap: var(--mpc-4-gap-17xl);
}
.mpc-4-custody {
  position: relative;
  line-height: 48px;
  width: 396px;
  height: 40px;
  flex-shrink: 0;
}
.mpc-4-custody,
.mpc-4-custody-parent,
.mpc-4-the-custody-function {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mpc-4-the-custody-function {
  position: relative;
  font-size: var(--mpc-4-font-size);
  color: var(--mpc-4-light-t1-day);
  width: 384px;
  height: 220px;
  flex-shrink: 0;
}
.mpc-4-custody-parent {
  position: absolute;
  top: 394px;
  left: 746px;
  border-radius: var(--mpc-4-br-9xl);
  background-color: var(--mpc-4-dark-t1);
  border: 4px solid var(--mpc-4-dark-b1);
  box-sizing: border-box;
  width: 460px;
  flex-direction: column;
  padding: var(--mpc-4-padding-21xl);
  gap: var(--mpc-4-gap-17xl);
}
.mpc-4-frame-child5 {
  opacity: 0.6;
}
.mpc-4-frame-child5,
.mpc-4-frame-child6,
.mpc-4-frame-child7 {
  position: relative;
  border-radius: var(--mpc-4-br-9xs);
  background-color: var(--mpc-4-light-t5);
  width: 12px;
  height: 160px;
  flex-shrink: 0;
}
.mpc-4-frame-child7 {
  background-color: var(--mpc-4-dark-tanglepay);
}
.mpc-4-rectangle-container {
  position: absolute;
  top: 272px;
  left: 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--mpc-4-gap-13xl);
}
.mpc-4-div5 {
  position: relative;
  width: 100%;
  height: 1280px;
  overflow: hidden;
  text-align: center;
  font-size: var(--mpc-4-font4-size);
  color: var(--mpc-4-dark-b1);
  font-family: var(--mpc-4-font);
}

.mpc-4-background-daytime-mode-202302-icon2 {
  width: 100vw;
  height: 134.688vw;
  object-fit: cover;
  position: absolute;
  top: 290.417vw;
  left: 0;
}

.mpc-4-use-cases-of {
  font-size: var(--mpc-4-font3-size);
  text-align: left;
  width: 39.7917vw;
  height: 2.70833vw;
  align-items: center;
  line-height: 2.91667vw;
  display: flex;
  position: absolute;
  top: 13.6458vw;
  left: 9.89583vw;
}

.mpc-4-assets-like-iota {
  font-size: var(--mpc-4-font-size);
  color: var(--mpc-4-light-t1-day);
  width: 21.6667vw;
  height: 11.4583vw;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.mpc-4-enter-child {
  height: 100%;
  width: 100%;
  border-radius: var(--mpc-4-br-base);
  background-color: var(--mpc-4-dark-t1);
  border: .20833vw solid var(--mpc-4-dark-tanglepay);
  box-sizing: border-box;
  position: absolute;
  inset: 0;
}

.mpc-4-enter1 {
  height: 80%;
  width: 93.75%;
  justify-content: center;
  align-items: center;
  line-height: 2.08333vw;
  display: flex;
  position: absolute;
  top: 10%;
  left: 3.57%;
}

.mpc-4-enter {
  width: 12.7083vw;
  height: 4.16667vw;
  font-size: var(--mpc-4-font2-size);
  color: var(--mpc-4-dark-tanglepay);
  flex-shrink: 0;
  position: relative;
}

.mpc-4-bridge-parent {
  border-radius: var(--mpc-4-br-9xl);
  background-color: var(--mpc-4-dark-t1);
  border: .20833vw solid var(--mpc-4-dark-b1);
  box-sizing: border-box;
  width: 25.625vw;
  padding: var(--mpc-4-padding-21xl);
  justify-content: center;
  align-items: center;
  gap: var(--mpc-4-gap-17xl);
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 20.5208vw;
  left: 9.89583vw;
}

.mpc-4-oracle {
  width: 20.1563vw;
  height: 2.08333vw;
  flex-shrink: 0;
  line-height: 2.5vw;
  position: relative;
}

.mpc-4-oracle, .mpc-4-oracle-can-be, .mpc-4-oracle-parent {
  justify-content: center;
  align-items: center;
  display: flex;
}

.mpc-4-oracle-can-be {
  font-size: var(--mpc-4-font-size);
  color: var(--mpc-4-light-t1-day);
  width: 20.2083vw;
  height: 11.4583vw;
  flex-shrink: 0;
  position: relative;
}

.mpc-4-oracle-parent {
  border-radius: var(--mpc-4-br-9xl);
  background-color: var(--mpc-4-dark-t1);
  border: .20833vw solid var(--mpc-4-dark-b1);
  box-sizing: border-box;
  width: 23.9583vw;
  padding: var(--mpc-4-padding-21xl);
  gap: var(--mpc-4-gap-17xl);
  flex-direction: column;
  position: absolute;
  top: 20.5208vw;
  left: 66.1458vw;
}

.mpc-4-custody {
  width: 20.625vw;
  height: 2.08333vw;
  flex-shrink: 0;
  line-height: 2.5vw;
  position: relative;
}

.mpc-4-custody, .mpc-4-custody-parent, .mpc-4-the-custody-function {
  justify-content: center;
  align-items: center;
  display: flex;
}

.mpc-4-the-custody-function {
  font-size: var(--mpc-4-font-size);
  color: var(--mpc-4-light-t1-day);
  width: 20vw;
  height: 11.4583vw;
  flex-shrink: 0;
  position: relative;
}

.mpc-4-custody-parent {
  border-radius: var(--mpc-4-br-9xl);
  background-color: var(--mpc-4-dark-t1);
  border: .20833vw solid var(--mpc-4-dark-b1);
  box-sizing: border-box;
  width: 23.9583vw;
  padding: var(--mpc-4-padding-21xl);
  gap: var(--mpc-4-gap-17xl);
  flex-direction: column;
  position: absolute;
  top: 20.5208vw;
  left: 38.8542vw;
}

.mpc-4-frame-child5 {
  opacity: .6;
}

.mpc-4-frame-child5, .mpc-4-frame-child6, .mpc-4-frame-child7 {
  border-radius: var(--mpc-4-br-9xs);
  background-color: var(--mpc-4-light-t5);
  width: .625vw;
  height: 8.33333vw;
  flex-shrink: 0;
  position: relative;
}

.mpc-4-frame-child7 {
  background-color: var(--mpc-4-dark-tanglepay);
}

.mpc-4-rectangle-container {
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--mpc-4-gap-13xl);
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 14.1667vw;
  left: 3.75vw;
}

.mpc-4-div5 {
  width: 100%;
  height: 66.6667vw;
  text-align: center;
  font-size: var(--mpc-4-font4-size);
  color: var(--mpc-4-dark-b1);
  font-family: var(--mpc-4-font);
  position: relative;
  overflow: hidden;
}

/*# sourceMappingURL=mpc.3dd5d002.css.map */
